@import 'compass';
@import 'compass/support';
@import 'setting';
@import 'mixin';
/* ======================================================================
 p_top
====================================================================== */
.p_top {
  &.p_page {
    &.tablet {
      padding: 80px 0 0;
    }
  }
  // 共通パーツ
  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  .ttl_1 {
    line-height: 1.5;
    margin: 85px 0 0;
    font-size: 28px;
    position: relative;
    padding: 0 0 23px;
    text-align: center;
    @include mq_max_768 {
      font-size: 20px;
      margin: 50px 0 0;
    }

    &:before {
      content: '';
      width: 50px;
      height: 5px;
      background: #009fe8;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .ttl_2 {
    font-size: 28px;
    line-height: 1.5;
    position: relative;
    padding: 0 0 23px;
    @include mq_max_768 {
      font-size: 20px;
    }

    &:before {
      content: '';
      width: 50px;
      height: 5px;
      background: #009fe8;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .ttl_3 {
    font-size: 28px;
    line-height: 1.5;
    color: #009fe8;
    @include mq_max_768 {
      font-size: 20px;
    }
  }

  .ttl_4 {
    font-size: 20px;
    line-height: 1.6;
    font-weight: bold;
    overflow: hidden;
    position: relative;
    line-height: 1.6em;
    max-height: 3.2em;
    text-align: justify;

    &::before {
      content: '...';
      position: absolute;
      right: 0;
      bottom: 6px;
      background-color: #fff;
      width: 1em;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1.6em;
      background-color: #fff;
    }
    @include mq_max_768 {
      font-weight: normal;
    }
  }

  .txt_1 {
    font-size: 16px;
    line-height: 2;

    &.textover {
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
    }

    &.textover_2row {
      overflow: hidden;
      position: relative;
      line-height: 2;
      max-height: 4em;
      text-align: justify;

      &::before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 5px;
        background-color: #fff;
        width: 1em;
      }

      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 2em;
        background-color: #fff;
      }
    }
    @include mq_max_768 {
      font-size: 14px;
      line-height: 1.9;
    }
  }

  .sml {
    font-size: 13px;
  }

  .sml12 {
    font-size: 12px;
  }

  .circle {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: #009fe8;
    display: table;

    > p {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      font-size: 16px;
      line-height: 1.5;
      color: #fff;
    }
  }

  .btn1 {
    width: 360px;
    height: 60px;
    border-radius: 30px;
    border: 3px solid #009fe8;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s linear;
    @include mq_max_768 {
      width: 235px;
      height: 45px;
      border: 1px solid #009fe8;
    }

    &:hover {
      background: #e5f6ff;
      opacity: 1;
      box-shadow: 0 15px 20px -4px rgba(0, 0, 0, .1);
    }

    > p {
      color: #009fe8;
      font-size: 16px;
      @include mq_max_768 {
        font-size: 14px;
      }
    }

    .position {
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 1;
      transform: translateY(-50%);

      .wrap {
        position: relative;
        background: #009fe8;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        @include mq_max_768 {
          width: 20px;
          height: 20px;
        }

        > .horizontal,
        > .vertical {
          content: '';
          position: absolute;
          top: 50%;
          left: 48%;
          right: 0;
          bottom: 0;
          margin: 0;
          width: 7px;
          height: 2px;
          background: #fff;
          @include mq_max_768 {
            width: 5px;
            height: 1px;
          }
        }

        > .vertical {
          transform: translate(-50%, -50%) rotate(40deg);
          transform-origin: right;
        }

        > .horizontal {
          transform-origin: right;
          transform: translate(-50%, -50%) rotate(-40deg);
        }
      }
    }
  }

  ._blue {
    color: #009fe8;
  }

  .l_main_visual_top {
    width: 100%;
    min-width: 1200px;
    height: 700px;
    min-height: 600px !important;
    position: relative;
    // background-color: #e5f6ff;
    background: #e5f6ff url(../img/common/loading.gif)center center no-repeat;
    background-size: 100px 100px;
    @include mq_max_768 {
      height: initial;
      min-width: 100vw !important;
      min-height: 106.666666667vw !important;
    }

    > div {
      background-repeat: no-repeat !important;
      @include mq_min_769 {
        background-position: center center !important;
        //min-height: 540px;
      }
      @include mq_max_768 {
        background-position: center bottom !important;
        // background-size: auto 100%!important;
        // top: 55px!important;
        background-size: cover !important;
      }
      @media screen and (max-width: 414px) {
        background-size: cover !important;
        top: 0 !important;
      }
    }

    > .inner {
      width: 100%;
      min-width: 1200px;
      //height: 645px;
      height: 100%;
      min-height: 600px !important;
      position: relative;
      //background-color: #e5f6ff;
      // background-image: url(/images/pc/common/loading.gif);
      // background-size: 100px 100px;
      @include mq_max_768 {
        min-height: 100% !important;
        min-width: 100% !important;
        height: 450px;
        top: initial !important;
        background-image: none;
      }

      .slider_outer {
        height: 100%;
        overflow: hidden;

        .slider {
          height: 100%;

          .slick-list {
            height: 100%;
          }

          .slick-track {
            height: 100%;
          }

          .slick-slide {
            height: 100%;

            > div {
              height: 100%;

              > .item {
                height: 100%;
                min-height: 400px;

                &._01 {
                  background-image: url("../img/top/pc/slide1.png");
                  background-position: center center;
                  background-size: cover;
                  @include mq_max_768 {
                    background-image: url("../img/top/sp/slide1.png");
                    background-position: 70% 50%;
                  }
                }

                &._02 {
                  background-image: url("../img/top/pc/slide2.png");
                  background-position: center center;
                  background-size: cover;
                  @include mq_max_768 {
                    background-image: url("../img/top/sp/slide2.png");
                  }
                }

                &._03 {
                  background-image: url("../img/top/pc/slide3.png");
                  background-position: center center;
                  background-size: cover;
                  @include mq_max_768 {
                    background-image: url("../img/top/sp/slide3.png");
                  }
                }
              }
            }
          }

          .slick-slide img {
            transition: 4s ease;

            &.u_pc {
              display: block;
              @include mq_max_768 {
                display: none;
              }
            }

            &.u_sp {
              display: none;
              @include mq_max_768 {
                display: block;
              }
            }
          }
        }
        // .item {
        //   width: 100%;
        //   height: 100%;
        //   >.image {
        //     height: 100%;
        //     object-fit: cover;
        //     @include mq_max_768 {
        //       width: 100%;
        //       height: 100%;
        //     }
        //     @media screen and (min-width:1550px) {
        //       width: auto;
        //     }
        //   }
        // }
      }

      &:before {
        content: "";
        background: url("../img/top/img_service.png") no-repeat center center/contain;
        position: absolute;
        width: 575px;
        height: 442px;
        bottom: -75px;
        left: 50%;
        margin: 0 0 0 -564px;
        z-index: 1;
        @include mq_max_768 {
          content: '';
          display: none;
        }
      }

      > .position {
        position: absolute;
        bottom: 70%;
        left: 50%;
        display: inline-block;
        margin: 0 0 0 -550px;
        overflow: hidden;
        z-index: 20;
        @include mq_max_768 {
          width: 100%;
          transform: translateX(-50%);
          margin: 0;
          font-size: 20px;
          text-align: center;
          bottom: auto;
        }

        > .catch_txt {
          position: relative;

          &:after,
          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: rgba(0, 159, 232, 0.65);
            transition: all 0.5s ease;
          }

          &:after {
            z-index: 3;
          }

          &._active {
            &:after,
            &:before {
              left: 0;
            }
          }

          &._after {
            &:after {
              left: 100%;
            }
          }

          > .txt1 {
            position: relative;
            z-index: 2;
            padding: 12.5px 20px;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: -0.045em;
            color: transparent;
            @include mq_max_768 {
              padding: 10px 0;
              font-size: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  > .u_sp.imgwrap {
    position: relative;
    z-index: 1;
    margin: -107px 0 0;

    .catch_img {
      text-align: center;

      > img {
        width: 277px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  .convwrap {
    display: flex;
    justify-content: center;
    margin: 125px auto 50px;
    @include mq_max_768 {
      flex-direction: column;
      padding: 0 5.3%;
      margin: 45px 0 25px;
    }

    .conv {
      width: 470px;
      height: 185px;
      border: 1px solid $color_0;
      background: linear-gradient(90deg, #69c6f1, #05A1E8);
      padding: 35px 0 0;
      box-shadow: 0 15px 20px -4px rgba(0, 0, 0, 0.1);
      @include mq_max_768 {
        width: 100%;
        height: 125px;
        padding: 25px 0 0;
        border: none;
      }

      .link {
        height: 100%;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);

        .ttl {
          color: $color_0;
          font-size: 2.0em;
          font-weight: bold;
          margin: 0 0 28px;
          @include mq_max_768 {
            font-size: 1.5em;
            margin: 0 0 17px;
          }
        }

        .btn {
          width: 350px;
          height: 60px;
          background: $color_0;
          @include flex_scc;
          border-radius: 30px;
          position: relative;
          transition: all 0.3s ease;
          @include mq_max_768 {
            width: 245px;
            height: 45px;
          }

          .txt {
            color: $color_2;
            font-size: 1.6em;
            font-weight: bold;
            @include mq_max_768 {
              font-size: 1.4em;
              margin-top: 1px;
            }
          }

          &:after,
          &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto 0;
          }

          &:before {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: $color_2;
            right: 17px;
            @include mq_max_768 {
              width: 21px;
              height: 21px;
              right: 12px;
            }
          }

          &:after {
            width: 6px;
            height: 6px;
            border-top: 2px solid $color_0;
            border-right: 2px solid $color_0;
            border-radius: 0 1px 0 0;
            transform: rotate(45deg);
            right: 28px;
            @include mq_max_768 {
              width: 4px;
              height: 4px;
              right: 20px;
            }
          }
          @include mq_min_769 {
            &:hover {
              background: $color_3;
              box-shadow: 0 15px 20px -4px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      &._pt2 {
        background: $color_6;

        .link {
          .btn {
            &:hover {
              background: #fff3e5;
            }

            &:before {
              background: $color_6;
            }

            .txt {
              color: $color_6;
            }
          }
        }
      }

      &:nth-of-type(2) {
        margin: 0 0 0 70px;
        background: linear-gradient(90deg, #448fe6, #045bc1);
        @include mq_max_768 {
          margin: 20px 0 0;
        }

        .link {
          .btn {
            &:before {
              background: #055cc2;
            }
          }
        }
      }
    }
  }

  .topnews {
    height: 114px;
    @include mq_max_768 {
      height: auto;
      margin: 0 0 20px;
    }

    > .inner {
      height: 100%;
      position: relative;

      > .container {
        width: 580px;
        height: 100%;
        position: absolute;
        right: 0;
        @include flexbox();
        @include align-items(center);
        @include mq_max_768 {
          height: auto;
          margin: 10px 0;
          position: static;
          width: 100%;
        }
      }

      .newswrap {
        &.u_pc {
          width: 555px;
          height: 53px;
          position: relative;
          left: 25px;

          &:before {
            content: "";
            width: 5px;
            height: 53px;
            background: $color_2;
            position: absolute;
            left: -25px;
          }

          .cttl {
            line-height: 1;
            margin: 0 0 15px;
          }

          .singlewrap {
            @include flexbox();

            .date {
              width: 170px;
              //margin: 0 25px 0 0;
              line-height: 1;
            }

            .ttl_outer {
              //width: 340px;
              width: 380px;
              //margin: 0 10px 0 0;
              .ttl {
                display: block;
                padding: 0 0 2px;
                line-height: 1;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
                text-overflow: ellipsis;

                .link {
                  max-width: 100%;
                  padding: 0 0 2px;
                  @include a_tag($color_2, $color_2);
                  background: linear-gradient(transparent 92%, $color_2 0%);
                  transition: all 0.5s linear;

                  &:hover {
                    background: none;
                  }
                }
              }
            }

            .new {
              font-size: 1.3rem;
              color: $color_6;
              font-weight: bold;
              line-height: 1;
              position: relative;
              top: 2px;
            }
          }

          .linkarchive {
            line-height: 1;
            position: absolute;
            top: 0;
            right: 0;

            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background: $color_2;
              bottom: -1px;
              left: 0;
              transition: all 0.5s linear;
            }

            .link {
              @include a_tag($color_2, $color_2);
            }

            &:hover {
              &:before {
                content: none;
              }
            }
          }
        }

        &.u_sp {
          width: calc(100% - 25px);
          position: relative;
          left: 25px;

          &:before {
            content: "";
            width: 5px;
            height: 100%;
            background: $color_2;
            position: absolute;
            left: -25px;
          }

          .cttl {
            font-size: 20px;
            line-height: 1;
            margin: 0 0 10px;
          }

          .singlewrap {
            @include flexbox();
            margin: 0 0 8px;

            .date {
              margin: 0 15px 0 0;
              line-height: 1;
            }

            .new {
              color: $color_6;
              font-weight: bold;
              line-height: 1;
            }
          }

          .ttl_outer {
            width: 100%;

            .ttl {
              display: block;
              padding: 0 0 2px;
              line-height: 1;
              overflow: hidden;
              white-space: nowrap;
              width: 100%;
              text-overflow: ellipsis;

              .link {
                max-width: 100%;
                color: $color_2;
                padding: 0 0 2px;
                @include a_tag($color_2, $color_2);
                background: linear-gradient(transparent 92%, $color_2 0%);
              }
            }
          }

          .linkarchive {
            line-height: 1;
            position: absolute;
            top: 0;
            right: 0;

            &:before {
              content: '';
              position: absolute;
              width: 100%;
              height: 1px;
              background: $color_2;
              bottom: -1px;
              left: 0;
            }

            .link {
              @include a_tag($color_2, $color_2);
            }
          }
        }
      }
    }
  }

  .section {
    > .inner {
      width: 1200px;
      margin: 0 auto;
      @include mq_max_768 {
        width: 100%;
        padding: 0 4.6875%;
      }

      .banner_area {
        width: 720px;
        position: relative;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
        @include mq_max_768 {
          width: 100%;
          margin: auto;
          padding: 25px 40px;
        }

        > .banner {
          display: block;
          width: 350px;

          > img {
            width: 100%;
          }

          &:nth-child(n + 3) {
            @include mq_min_769 {
              margin: 20px 0 0;
            }
          }
        }

        .slick-list {
          margin: 0 -10px;
        }

        .slick-slide {
          margin: 0 10px;

          .fit {
            @include mq_max_768 {
              width: 100%;
            }
          }
        }

        .slick-next {
          right: 10px;

          &:before {
            content: '>';
            color: #009fe8;
          }
        }

        .slick-prev {
          left: 10px;

          &:before {
            content: '<';
            color: #009fe8;
          }
        }
      }
    }

    &.sec_1 {
      padding: 0 0 100px;
      @include mq_max_768 {
        padding: 0 0 50px;
      }

      > .inner {
        > .ttl_1 {
          font-size: 26px;
          font-weight: normal;
          line-height: 47px;
          @include mq_max_768 {
            padding: 0 0 27px;
            font-size: 20px;
            line-height: 28px;
          }

          .u_bold {
            font-size: 28px;
            @include mq_max_768 {
              font-size: 22px;
            }
          }

          .bg {
            display: inline-block;
            margin: 10px 10px 0;
            padding: 1.5px 10px;
            border-radius: 5px;
            background: #d3e6fb;
            @include mq_max_768 {
              margin: 10px 0 0;
              padding: 7px 10px;
              width: 100%;
            }

            &:nth-of-type(2) {
              @include mq_max_768 {
                margin: 20px 0 0;
              }
            }

            &:nth-of-type(3) {
              @include mq_max_768 {
                margin: 10px 0;
              }
            }
          }
        }

        .desc {
          width: fit-content;
          margin: 50px auto 70px;
          @include mq_max_768 {
            margin: 30px auto 35px;
            font-size: 16px;
            line-height: 28px;
          }
        }

        .scroll {
          display: none;
          gap: 13.5px;
          align-items: center;
          justify-content: center;
          height: 50px;
          margin: 0 auto 30px;
          background: #E5F6FF;
          border-radius: 3px;
          @include mq_max_768 {
            display: flex;
          }

          .img {
            width: 42.83px;
          }
        }

        .img1 {
          width: fit-content;
          margin: 0 auto;
          @include mq_max_768 {
            width: 104.6875%;
            margin: 0 auto 60px;
            overflow: auto;
          }

          .img {
            display: block;
            width: 630px;
          }
        }

        .img2 {
          display: block;
          width: fit-content;
          margin: 210px auto 0;
          @include mq_max_768 {
            width: 104.6875%;
            margin: 0 auto;
            overflow: auto;
          }

          .img {
            display: block;
            width: 1015px;
            @include mq_max_768 {
              width: 836px;
            }
          }

          .flex {
            display: flex;
            gap: 190px;
            width: 1015px;
            padding: 0 10px;
            letter-spacing: 1.4px;
            white-space: nowrap;
            @include mq_max_768 {
              gap: 165px;
              width: 836px;
            }

            .txt_1 {
              width: 402px;
              @include mq_max_768 {
                width: 330px;
                font-size: 16px;
                letter-spacing: 0;
              }
            }
          }
        }

        .video {
          margin: 100px auto 60px;
          display: block;
          width: 950px;
          height: 534.44px;
          @include mq_max_768 {
            width: 100%;
            height: calc(56.25vw - 4.6875vw);
            margin: 55px auto 30px;
          }
        }

        .btn1 {
          margin: 0 auto;
          @include mq_max_768 {
            width: 100%;
            border: solid 3px #009fe8;
            height: 60px;
          }

          > p {
            @include mq_max_768 {
              font-size: 16px;
            }
          }

          .position {
            .wrap {
              @include mq_max_768 {
                width: 30px;
                height: 30px;
              }

              .horizontal,
              .vertical {
                @include mq_max_768 {
                  width: 7px;
                  height: 2px;
                }
              }
            }
          }
        }
      }
    }

    &.sec_2 {
      background: #e5f6ff;
      padding: 100px 0;
      min-width: 1200px;
      @include mq_max_768 {
        padding: 20px 0;
        min-width: initial;
      }

      > .inner {
        > .contents {
          width: 1160px;
          margin: 0 auto;
          background: #fff;
          @include mq_max_768 {
            width: 100%;
          }

          &:first-child {
            margin: 0 auto 50px;
            @include mq_max_768 {
              margin: 0 auto 30px;
            }
          }

          > .inner {
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            @include mq_max_768 {
              flex-wrap: wrap;
            }

            &._pt1 {
              flex-direction: row-reverse;
            }

            > .txtwrap,
            > .imgwrap {
              width: 50%;
              @include mq_max_768 {
                width: 100%;
              }
            }

            > .imgwrap {
              > img {
                width: 100%;
              }
            }

            > .txtwrap {
              padding: 50px;
              position: relative;
              @include mq_max_768 {
                display: block;
                padding: 50px 20px;
              }

              .subwrap {
                @include mq_max_768 {
                  text-align: center;
                  margin: 30px 0 0;
                }
              }

              .txt_1 {
                margin: 40px 0 0;
                @include mq_max_768 {
                  margin: 40px auto 0;
                }

                .superscript {
                  font-size: 1.2rem;
                  position: relative;
                  top: -4px;
                  @include mq_max_768 {
                    font-size: 1rem;
                  }
                }

                &._blue {
                  display: inline-block;
                  margin: 5px 0 0;
                  position: relative;
                  //visibility: hidden;
                  //pointer-events: none;
                  @include mq_max_768 {
                    margin: 5px auto 0;
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background: #009fe8;
                    bottom: 5px;
                    left: 0;
                    transition: all linear 0.5s;
                  }
                }

                > .sml {
                  display: block;
                  @include mq_max_768 {
                    margin: 30px 0 0;
                  }
                }

                &:hover {
                  &._blue {
                    &:before {
                      content: none;
                    }
                  }
                }
              }

              .btn1 {
                position: absolute;
                bottom: 50px;
                left: 50%;
                transform: translateX(-50%);
                @include mq_max_768 {
                  position: static;
                  margin: 50px auto 0;
                  left: 0;
                  transform: translateX(0);
                }
              }
            }
          }
        }
      }
    }

    &.sec_3 {
      padding: 0 0 100px;

      > .inner {
        > .column_contents {
          > .inner {
            padding: 0 20px;

            > .lists {
              margin: 50px auto;
              display: -webkit-flex;
              display: -ms-flex;
              display: flex;

              .slick-list {
                margin: 0 -14px;
              }

              .slick-slide {
                @include mq_max_768 {
                  border: 1px solid #e6e6e6;
                  margin: 0 10px;
                }
              }

              .slick-next {
                &:before {
                  content: '>';
                  color: #009fe8;
                }
              }

              .slick-prev {
                &:before {
                  content: '<';
                  color: #009fe8;
                }
              }

              .list {
                width: calc(100% / 3);
                border: 1px solid #e6e6e6;
                box-sizing: border-box;
                position: relative;
                transition: all 0.2s linear;
                @include mq_max_768 {
                  outline: none;
                  border-left-width: 1px;
                }

                &:nth-child(3n+1) {
                  border-left-width: 1px;
                  @include mq_max_768 {
                    border-left-width: 1px;
                  }
                }

                &:nth-child(n+5) {
                  border-left-width: 0;
                  @include mq_max_768 {
                    border-left-width: 1px;
                  }
                }

                &:hover {
                  //outline: 2px solid #009fe8;
                  box-shadow: 0 10px 20px 5px rgba(0, 0, 0, .1);
                  opacity: 1;
                  border-color: transparent;
                  @include mq_max_768 {
                    outline: inherit;
                    box-shadow: none;
                    border-color: #e6e6e6;
                  }

                  &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    z-index: 1;
                    border: 2px solid #009fe8;
                    pointer-events: none;
                    @include mq_max_768 {
                      border: none;
                    }
                  }
                }

                > a {
                  padding: 30px;
                  display: block;
                  width: 100%;
                  height: 100%;
                  color: #323232;
                  // transition: all .2s linear;
                  @include mq_max_768 {
                    padding: 20px;
                  }

                  &:hover {
                    box-shadow: 0 10px 20px 5px rgba(0, 0, 0, .1);
                    opacity: 1;
                    @include mq_max_768 {
                      outline: inherit;
                      box-shadow: none;
                    }

                    > .imgwrap {
                      > img {
                        opacity: 1;
                      }
                    }
                  }

                  > .imgwrap {
                    width: 100%;
                    height: 210px;
                    @include mq_max_768 {
                      width: 100%;
                      height: 100%;
                    }

                    > img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }

                  .c_ico_3 {
                    margin: 15px 0 10px;
                    @include mq_max_768 {
                      font-size: 12px;
                    }

                    .inner {
                      .u_txt_1 {
                        @include mq_max_768 {
                          font-size: 12px;
                        }
                      }
                    }
                  }

                  > .txtwrap {
                    > .ttl_4 {
                      margin: 0 0 10px;
                    }
                  }
                }
              }

              .slick-slide {
                &:nth-of-type(n+2) {
                  .list {
                    @include mq_min_769 {
                      border-left: none;
                    }
                  }
                }
              }
            }

            > .btnwrap {
              > .btn1 {
                margin: 50px auto 0;
              }
            }
          }
        }

        > .news_contents {
          padding: 100px 0;
          @include mq_max_768 {
            padding: 50px 0;
          }

          > .ttl_1 {
            margin: 0 0 40px;
          }

          > .txt {
            position: relative;
            font-size: 16px;
            margin: 25px 0 0 175px;
            @include mq_max_768 {
              padding: 30px 0;
              margin: 0;
              font-size: 14px;
              border-top: 1px solid #e6e6e6;
              border-bottom: 1px solid #e6e6e6;
            }

            &.first {
              margin: 0 0 0 175px;
              @include mq_max_768 {
                border-top: none;
                margin: 0;
                padding: 0 0 30px;
              }

              > a {
                position: relative;

                > .new {
                  content: 'NEW';
                  color: #f29d00;
                }
              }
            }

            &:nth-child(2),
            &:nth-child(3) {
              border-bottom: none;
            }

            > span {
              position: absolute;
              @include mq_max_768 {
                position: static;
              }
            }

            > a {
              display: inline-block;
              margin: 0 0 0 140px;
              color: #009fe8;
              @include mq_max_768 {
                padding: 0;
                margin: 5px 0 0;
                line-height: 1.5;
              }
            }
          }

          > .btnwrap {
            > .btn1 {
              margin: 80px auto 0;
              @include mq_max_768 {
                margin: 50px auto 0;
              }
            }
          }
        }
      }
    }

    &.sec_4 {
      background: $color_4;
      padding: 100px 0;
      @include mq_max_768 {
        padding: 20px 0;
      }

      > .inner {
        @include flexbox();
        @include mq_max_768 {
          display: block;
        }

        .contents {
          width: 550px;
          height: 645px;
          background: $color_0;
          padding: 50px;
          @include mq_max_768 {
            width: 100%;
            height: 100%;
            padding: 45px 20px;
          }

          > .ttl {
            margin: 0 0 50px;
            @include mq_max_768 {
              margin: 0;
            }
          }

          > .list {
            @include mq_max_768 {
              margin: 25px 0;
            }

            > .item {
              height: 106px;
              border-bottom: 1px solid #E6E6E6;
              padding: 20px 0 0;
              @include mq_max_768 {
                height: 100%;
                padding: 16px 0;
              }

              .infowrap {
                @include flexbox();
                margin: 0;
                @include mq_max_768 {
                  margin: 0 0 4px;
                }

                .date {
                  @include flexbox();
                  @include align-items(center);
                  margin: 0 10px 0 0;
                }

                .icon {
                  margin: 0 9px 0 0;
                }

                .new {
                  @include flexbox();
                  @include align-items(center);
                  font-weight: bold;
                  color: $color_6;
                }
              }

              > .link {
                @include a_tag($color_2, $color_2);

                .outer {
                  position: relative;

                  .ttl {
                    overflow: hidden;
                    white-space: nowrap;
                    width: 100%;
                    text-overflow: ellipsis;
                    height: 42px;
                    @include mq_max_768 {
                      text-overflow: clip;
                      white-space: normal;
                      position: relative;
                      max-height: 4em;
                      text-align: justify;

                      &:before {
                        content: '...';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        background-color: #fff;
                        width: 1em;
                      }

                      &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        width: 1em;
                        height: 2em;
                        background-color: #fff;
                      }
                    }

                    .txt {
                      padding: 0 0 2px;
                      background: linear-gradient(transparent 92%, $color_2 0%);
                      transition: all 0.2s linear;

                      &:hover {
                        background: none;
                      }
                    }
                  }
                }
              }

              > .outer {
                position: relative;

                .ttl {
                  overflow: hidden;
                  white-space: nowrap;
                  width: 100%;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          .btnwrap {
            @include flexbox();
            @include justify-content(center);
            margin: 50px 0 0;
          }

          &._event {
            margin: 0 100px 0 0;
            @include mq_max_768 {
              margin: 0 0 20px;
            }
          }

          &._news {
            > .list {
              > .item {
                .link {
                  .outer {
                    .ttl {
                      .txt {
                        position: relative;

                        &:after {
                          content: "";
                          width: 12px;
                          height: 12px;
                          background: url("../img/common/icon_outlink.png") no-repeat;
                          background-size: contain;
                          display: inline-block;
                          position: absolute;
                          bottom: 2px;
                          margin: 0 0 0 6px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ly-section.lv-1.is-top.util-bgc-pickup.new {
    min-width: 1200px;
    padding: 0;
    @include mq_max_768 {
      min-width: initial;
    }

    .ly-section.has-underline.is-app {
      padding: 80px 0 100px;
      background: #e5f6ff;
      @include mq_max_768 {
        padding: 35px 0;
      }

      .util-inner {
        @include mq_max_768 {
          padding: 0 4.6875%;
        }

        .mod-box.is-app {
          &._last {
            .app-wrap {
              padding-bottom: 0;
              @include mq_max_768 {
                padding: 15px 0;
              }
            }
          }

          .app-wrap {
            @include mq_max_768 {
              display: -webkit-flex;
              display: -ms-flex;
              display: flex;
              flex-direction: initial;
              align-items: center;
              padding: 15px 0;
            }

            .app-caption {
              @include mq_max_768 {
                width: calc(100% - 130px);
              }

              .app-caption-icon {
                .icon-image {
                  @include mq_max_768 {
                    width: 40px;
                  }
                }
              }

              .app-caption-title {
                @include mq_max_768 {
                  padding: 0 0 0 10px;
                  vertical-align: middle;
                }

                .mod-box-title {
                  @include mq_max_768 {
                    font-size: 14px;
                  }
                }

                .mod-box-text {
                  @include mq_max_768 {
                    font-size: 10px;
                    margin: 2px 0 0;
                  }
                }
              }
            }

            .app-store {
              @include mq_max_768 {
                width: 120px;
                margin: 0;
              }
              @media screen and (max-width: 320px) {
                width: 110px;
                margin: 0;
              }

              .app-store-btn {
                @include mq_max_768 {
                  padding: 0;
                }

                &._none {
                  @include mq_max_768 {
                    display: none;
                  }
                }

                .store-btn-link {
                  transition: all 0.2s linear;
                }
              }
            }
          }
        }
      }
    }
  }
}

.p_top .section.sec_4.w100 {
  padding: 0;
  margin-top: 30px;
  background: none;

  .contents._event {
    margin: 0 auto;
    border: 1px solid #e6e6e6;
    width: 100%;
    height: auto;
  }

  .l_content_size_pc_1.inner {
    @include mq_max_768 {
      padding: 0;
    }
  }

  .c_btn_1 {
    @include mq_max_768 {
      width: 260px;
    }
  }
}

._dn {
  display: none;
}

.p_top .section.sec_4 > .inner .contents > .list > .item > .link .outer .ttl.ho,
.p_top .section.sec_4 > .inner .contents > .list > .item > .link .outer .ttl.u_sp {
  @include mq_max_768 {
    height: auto;
  }
}
//
//
//
//
//
//
//
//
//
//
//